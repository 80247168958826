import { apolloClient } from '@/plugins/apollo'

import startPublicEvaluationGQL from '@/graphql/mutation/public-evaluation-start.gql'
import preloadPublicEvaluationGQL from '@/graphql/query/public-evaluation-preload.gql'
import loadPublicEvaluationGQL from '@/graphql/query/public-evaluation-load.gql'
import extendPublicEvaluationMacrotrendGQL from '@/graphql/query/public-evaluation-macrotrend-extend.gql'
import evaluatePublicEvaluationGQL from '@/graphql/mutation/public-evaluation-evaluate.gql'
import checkEvaluationParticipantGQL from '@/graphql/query/public-evaluation-participant-valid.gql'
import publicEvaluationSubmitFeedbackGQL from '@/graphql/mutation/public-evaulation-send-feedback.gql'

export const preloadPublicEvaluation = async (publicEvaluationId, imageHeight = 150) => {
  try {
    const response = await apolloClient.query({
      query: preloadPublicEvaluationGQL,
      fetchPolicy: 'network-only',
      variables: {
        id: publicEvaluationId,
        height: imageHeight
      }
    })

    return response.data.publicEvaluation
  } catch (error) {
    console.log('%cError on preloadPublicEvaluation: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const startPublicEvaluation = async (publicEvaluationId) => {
  try {
    const response = await apolloClient.mutate({
      mutation: startPublicEvaluationGQL,
      variables: {
        input: {
          publicEvaluationId: publicEvaluationId
        }
      }
    })
    return response.data.publicEvaluationStart
  } catch (error) {
    console.log('%cError on startPublicEvaluation: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const loadPublicEvaluation = async (publicEvaluationId, participantId, imageHeight = 150) => {
  try {
    const response = await apolloClient.query({
      query: loadPublicEvaluationGQL,
      variables: {
        id: publicEvaluationId,
        participantId,
        height: imageHeight
      }
    })

    return response.data.publicEvaluation
  } catch (error) {
    console.log('%cError on loadPublicEvaluation: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const extendPublicEvaluationMacrotrend = async (publicEvaluationId, macrotrendId, locale) => {
  try {
    const response = await apolloClient.query({
      query: extendPublicEvaluationMacrotrendGQL,
      variables: {
        publicEvaluationId: publicEvaluationId,
        macrotrendId: macrotrendId,
        locale: locale
      }
    })

    return response.data.publicEvaluationMacrotrend.microtrends
  } catch (error) {
    console.log('%cError on extendPublicEvaluationMacrotrend: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const evaluatePublicEvaluation = async (publicEvaluationId, participantId, macrotrendId, values) => {
  try {
    const response = await apolloClient.mutate({
      mutation: evaluatePublicEvaluationGQL,
      variables: {
        input: {
          publicEvaluationId,
          participantId,
          macrotrendId,
          values
        }
      }
    })

    return response.data.publicEvaluationEvaluate
  } catch (error) {
    console.log('%cError on evaluatePublicEvaluationGQL: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const checkEvaluationParticipantValid = async (publicEvaluationId, participantId) => {
  try {
    const response = await apolloClient.query({
      query: checkEvaluationParticipantGQL,
      variables: {
        publicEvaluationId: publicEvaluationId,
        participant: participantId
      }
    })

    return response.data.publicEvaluationParticipantValid
  } catch (error) {
    console.log('%cError on checkEvaluationParticipantGQL: ', 'color:red', error) // eslint-disable-line no-console
    throw error
  }
}

export const publicEvaluationSubmitFeedback = async (publicEvaluationId, feedback) => {
  const response = await apolloClient.mutate({
    mutation: publicEvaluationSubmitFeedbackGQL,
    variables: {
      input: {
        publicEvaluationId,
        feedback
      }
    }
  })

  return response.data.publicEvaluationSubmitFeedback
}
