import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/sentry'
import store from './store'
import apolloProvider from './plugins/apollo'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'

import '@mdi/font/css/materialdesignicons.css'
import 'video.js/dist/video-js.css'
import 'hooper/dist/hooper.css'
import './styles/app.sass'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
