<template>
  <v-app-bar
    app
    color="white"
    height="80"
  >
    <a href="https://www.trendmanager.com/"
       target="_blank">
      <v-img
        width="225"
        height="20"
        contain
        class="shrink"
        alt="TRENDMANAGER"
        title="TRENDMANAGER"
        src="~@/assets/imgs/trendmanager-logo.svg"
        transition="scale-transition"
      />
    </a>

    <v-spacer />

    <v-btn
      :href="$t('contact')"
      class="mr-3 hidden-xs-only"
      target="_blank"
      color="primary"
      dark
    >
      {{ $t('requestLogin') }}
      <v-icon right>mdi-open-in-new</v-icon>
    </v-btn>
    <v-btn
      :href="$t('contact')"
      class="mr-3 hidden-sm-and-up"
      target="_blank"
      color="primary"
      dark
    >
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AppHeader extends Vue {
}
</script>

<i18n>
{
  "de": {
    "requestLogin": "DEN TRENDMANAGER KENNENLERNEN",
    "contact": "https://www.trendmanager.com/de/"
  },
  "en": {
    "requestLogin": "GET TO KNOW THE TRENDMANAGER",
    "contact": "https://www.trendmanager.com/en/"
  }
}
</i18n>
