<template>
  <v-card>
    <v-card-title>
      <h1>Imprint</h1>
    </v-card-title>

    <v-card-text>
      <h2>TRENDONE GMBH</h2>
      <address>
        Gasstraße 2<br />
        22761 Hamburg / Germany<br />
        Tel: <a class="std"
                href="tel:+49 40 526 778 0">+49 40 526 778 0</a><br />
        Fax: +49 40 526 778 36<br />
        Email: <a href="mailto:info@trendone.com">info@trendone.com</a>
      </address>

      <p>CEO and responsible for this website: Nils Müller and Christoph M. Kadereit</p>

      <h4>Disclaimer:</h4>
      <p>
        In spite of careful monitoring of contents, we do not assume any liability for the content of external
        links. The operators of these linked websites are solely responsible for the content.
      </p>

      <h4>Liability for content</h4>
      <p>
        The contents of these pages were created with great care. However, we cannot guarantee the accuracy,
        completeness and up-to-dateness of all the contents.
      </p>

      <p>
        As a service provider and as per § 7, paragraph 1 of the German Telemedia Act (TMG), we are responsible
        for our own contents on these pages in accordance with the general legislation. According to §§ 8 to 10
        of the TMG, we are, however, not obliged as a service provider to monitor transferred or stored
        third-party information or to determine any circumstances which may point towards unlawful activities.
        Obligations to remove or block the use of information in accordance with the general legislation shall
        remain unaffected. This kind of liability is, however, only possible from the moment that knowledge of
        the specific infringement has been obtained. When corresponding infringements become known, we shall
        remove these contents immediately.
      </p>

      <h4>Liability for links</h4>
      <p>
        This site contains links to external, third-party websites whose content we cannot influence. We
        therefore accept no liability for such third-party content. The respective provider or operator of such
        websites is always responsible for the content of linked websites. The linked websites were checked for
        any legal infringements at the time the links were created. No unlawful content was detected at the time
        the links were created. We cannot be expected to permanently check the content of the linked websites
        without having any concrete proof or suspicion of legal infringements. Should we find any legal
        infringements in the linked websites, we shall remove the links immediately.
      </p>

      <h4>Copyright</h4>
      <p>
        The content and works provided on these web pages are subject to German copyright law. Reproduction,
        processing, dissemination and any type of use beyond what is permitted under copyright requires written
        authorization from the respective author and/or creator. Downloads and copies of this website are
        permitted exclusively for private, non-commercial use. Insofar as the contents of this website were not
        compiled by the operator, the copyrights of third parties shall be respected. Contents from third
        parties have, in particular, been identified as such. We ask you to notify us should you come across any
        copyright infringements. Should we discover any legal infringements, we shall remove the content
        immediately.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'meta-en-imprint'
}
</script>

<style lang="sass"
       scoped>
  p
    text-align: justify
  a
    text-decoration: none
    color: black
  address
    font-style: normal
</style>
