<template>
  <router-view></router-view>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EmptyLayout extends Vue {
}
</script>
