import * as ACTION from './action-types'
import * as MUTATION from './mutation-types'
import * as GETTER from './getter-types'
import { LOCALE } from './getter-types'

import * as api from '../api'
import { ERROR_404 } from '@/model/errors'

export default {
  [ACTION.PRELOAD_PUBLIC_EVALUATION]: async ({ getters, commit }, publicEvaluationId) => {
    try {
      commit(MUTATION.SET_LOADING, true)

      const evaluation = await api.preloadPublicEvaluation(publicEvaluationId)

      commit(MUTATION.SET_PUBLIC_EVALUATION, {
        loaded: false,
        id: evaluation.id,
        intro: evaluation.intro
      })
    } catch (error) {
      commit(MUTATION.SET_ERROR, error.graphQLErrors[0].code || ERROR_404)
    } finally {
      commit(MUTATION.SET_LOADING, false)
    }
  },

  [ACTION.LOAD_PUBLIC_EVALUATION]: async ({ getters, commit }, publicEvaluationId) => {
    commit(MUTATION.SET_LOADING, true)

    const participantId = getters[GETTER.GET_PARTICIPANT_ID](publicEvaluationId)
    const evaluation = await api.loadPublicEvaluation(publicEvaluationId, participantId)

    commit(MUTATION.SET_PUBLIC_EVALUATION, {
      loaded: true,
      id: evaluation.id,
      title: evaluation.title,
      intro: evaluation.intro,
      description: evaluation.description,
      criterias: evaluation.criterias,
      contacts: evaluation.contacts,
      feedbackIntro: evaluation.feedbackIntro,
      feedbackEnabled: evaluation.feedbackEnabled,
      macrotrends: evaluation.macrotrends.sort((m1, m2) => {
        const cmp = m1.megatrend.title.localeCompare(m2.megatrend.title)
        return cmp === 0
          ? m1.title.localeCompare(m2.title)
          : cmp
      }).map(elem => ({
        ...elem,
        microtrends: null
      }))
    })

    commit(MUTATION.SET_LOADING, false)
  },

  [ACTION.LOAD_PERSONALIZED_EVALUATION]: async ({ commit }, {
    publicEvaluationId,
    personalizedParticipantId
  }) => {
    commit(MUTATION.SET_LOADING, true)

    commit(MUTATION.SET_PERSONALIZED_EVALUATION, true)

    commit(MUTATION.ADD_PARTICIPANT_ID, {
      publicEvaluationId: publicEvaluationId,
      participantId: personalizedParticipantId
    })

    const evaluation = await api.loadPublicEvaluation(publicEvaluationId, personalizedParticipantId)

    commit(MUTATION.SET_PUBLIC_EVALUATION, {
      loaded: true,
      id: evaluation.id,
      title: evaluation.title,
      intro: evaluation.intro,
      description: evaluation.description,
      criterias: evaluation.criterias,
      contacts: evaluation.contacts,
      macrotrends: evaluation.macrotrends.sort((m1, m2) => {
        const cmp = m1.megatrend.title.localeCompare(m2.megatrend.title)
        return cmp === 0
          ? m1.title.localeCompare(m2.title)
          : cmp
      }).map(elem => ({
        ...elem,
        microtrends: null
      }))
    })

    commit(MUTATION.SET_LOADING, false)
  },

  [ACTION.EXTEND_MACROTREND]: async ({ getters, commit }, payload) => {
    commit(MUTATION.SET_LOADING, true)
    commit(MUTATION.EXTEND_MACROTREND, {
      macrotrendId: payload.macrotrendId,
      microtrends: await api.extendPublicEvaluationMacrotrend(payload.publicEvaluationId, payload.macrotrendId, getters[LOCALE])
    })

    commit(MUTATION.SET_LOADING, false)
  },

  [ACTION.START_PUBLIC_EVALUATION]: async ({ commit }, publicEvaluationId) => {
    commit(MUTATION.SET_PENDING, true)

    const participantId = await api.startPublicEvaluation(publicEvaluationId)

    commit(MUTATION.ADD_PARTICIPANT_ID, {
      publicEvaluationId,
      participantId
    })

    commit(MUTATION.SET_PENDING, false)
  },

  [ACTION.EVALUATE_PUBLIC_EVALUATION]: async ({ commit, getters }, payload) => {
    commit(MUTATION.SET_PENDING, true)

    const participantId = getters[GETTER.GET_PARTICIPANT_ID](payload.publicEvaluationId)
    const response = await api.evaluatePublicEvaluation(
      payload.publicEvaluationId,
      participantId,
      payload.macrotrendId,
      payload.values.map(elem => ({
        criteriaId: elem.criteria.id,
        value: parseFloat(elem.value)
      }))
    )

    if (response) {
      commit(MUTATION.UPDATE_EVALUATION, {
        macrotrendId: payload.macrotrendId,
        values: payload.values
      })
    }

    commit(MUTATION.SET_PENDING, false)
  },

  [ACTION.CHECK_EVALUATION_PARTICIPANT_VALID]: async ({ commit, getters }, publicEvaluationId) => {
    commit(MUTATION.SET_LOADING, true)
    const participantId = getters[GETTER.GET_PARTICIPANT_ID](publicEvaluationId)

    const response = await api.checkEvaluationParticipantValid(publicEvaluationId, participantId)

    if (!response) {
      commit(MUTATION.REMOVE_PARTICIPANT_ID, publicEvaluationId)
    }
    commit(MUTATION.SET_LOADING, false)
  },

  [ACTION.PUBLIC_EVALUATION_SEND_FEEDBACK]: async ({ commit, getters }, payload) => {
    try {
      commit(MUTATION.SET_LOADING, true)
      commit(MUTATION.SET_PENDING, true)

      await api.publicEvaluationSubmitFeedback(payload.publicEvaluationId, payload.feedback)
    } catch (error) {
      commit(MUTATION.SET_LOADING, false)
      commit(MUTATION.SET_PENDING, false)

      throw error
    }
  }
}
