/*
export type WindowWithConfig = Window & typeof globalThis & {
  config?: Record<string, string|Array<any>>
}
*/

// export function runtimeConfig (name: string, defaultValue?: any) {
export function runtimeConfig (name, defaultValue) {
  // const windowWithConfig = window as WindowWithConfig
  const windowWithConfig = window

  if (windowWithConfig.config && name in windowWithConfig.config) {
    return windowWithConfig.config[name]
  }

  if (name in process.env) {
    return process.env[name]
  }

  if (arguments.length === 2) {
    return defaultValue
  }

  throw new Error(`No runtime configuration "${name}"`)
}
