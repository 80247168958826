<template>
  <v-snackbar
    v-model="internal"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    rounded="0"
    top
    right
  >
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        icon
        @click="internal = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { RESET_SNACKBAR } from '@/store/mutation-types'
import { SNACKBAR } from '@/store/getter-types'

@Component
export default class Snackbar extends Vue {
  @Getter(SNACKBAR) snackbar
  @Mutation(RESET_SNACKBAR) resetSnackbar

  get internal () {
    return this.snackbar.message !== null
  }

  set internal (value) {
    if (!value) {
      this.resetSnackbar()
    }
  }
}
</script>
