<template>
  <v-app>
    <app-header />
    <v-main>
      <router-view />
    </v-main>
    <icon-lib />
    <snackbar />
    <app-footer />
  </v-app>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AppFooter from '../components/navigation/footer'
import AppHeader from '../components/navigation/header'
import IconLib from '../components/icon-lib'
import Snackbar from '../components/snackbar'

@Component({
  components: {
    AppFooter,
    AppHeader,
    IconLib,
    Snackbar
  }
})
export default class DefaultLayout extends Vue {
}
</script>
