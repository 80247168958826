import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1620D1',
        secondary: '#BDBDBD',
        accent: '#FF5252',
        error: '#E7001C',
        info: '#42A5F5',
        success: '#81C784',
        warning: '#FFA000'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})
