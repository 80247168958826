import { DE } from '@/model/locale'

export const initSnackbar = () => ({
  message: null,
  color: null,
  timeout: 3000
})

export const init = () => ({
  snackbar: initSnackbar(),
  pending: false,
  loading: false,
  locale: DE,
  ids: {},
  error: null,
  evaluation: {
    macrotrends: []
  },
  microtrend: null,
  personalizedEvaluation: false
})

const state = init()
export default state
