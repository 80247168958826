export const DESCRIPTION = 'DESCRIPTION'
export const GET_PARTICIPANT_ID = 'GET_PARTICIPANT_ID'
export const INTRO = 'GET_INTRO'
export const LOCALE = 'LOCALE'
export const MACROTRENDS = 'MACROTRENDS'
export const PENDING = 'PENDING'
export const LOADING = 'LOADING'
export const SNACKBAR = 'SNACKBAR'
export const TITLE = 'TITLE'
export const LOADED = 'LOADED'
export const CONTACTS = 'CONTACTS'
export const GET_MACROTREND = 'GET_MACROTREND'
export const CRITERIAS = 'CRITERIAS'
export const PROGRESS = 'PROGRESS'
export const PROGRESS_FORMATTED = 'PROGRESS_FORMATTED'
export const MICROTREND = 'MICROTREND'
export const COMPLETED = 'COMPLETED'
export const GET_NEXT_MACROTREND = 'NEXT_MACROTREND'
export const IS_MACROTREND_COMPLETE = 'IS_MACROTREND_COMPLETE'
export const ERROR = 'GET_ERROR'
export const FEEDBACK_ENABLED = 'FEEDBACK_ENABLED'
export const FEEDBACK_INTRO = 'FEEDBACK_INTRO'
export const IS_PERSONALIZED_EVALUATION = 'IS_PERSONALIZED_EVALUATION'
