import * as GETTER from './getter-types'

export default {
  [GETTER.GET_PARTICIPANT_ID]: (state) =>
    (publicEvaluationId) =>
      state.ids[publicEvaluationId] ?? null,

  [GETTER.INTRO]: (state) => state.evaluation && state.evaluation.intro ? state.evaluation.intro : null,

  [GETTER.LOCALE]: (state) => state.locale,

  [GETTER.LOADED]: (state) => state.evaluation && state.evaluation.loaded,

  [GETTER.MACROTRENDS]: (state) => state.evaluation && state.evaluation.macrotrends ? state.evaluation.macrotrends : [],

  [GETTER.TITLE]: (state) => state.evaluation && state.evaluation.title ? state.evaluation.title : null,

  [GETTER.DESCRIPTION]: (state) => state.evaluation && state.evaluation.description ? state.evaluation.description : null,

  [GETTER.FEEDBACK_ENABLED]: (state) => state.evaluation.feedbackEnabled,

  [GETTER.FEEDBACK_INTRO]: (state) => state.evaluation && state.evaluation.feedbackIntro ? state.evaluation.feedbackIntro : null,

  [GETTER.IS_PERSONALIZED_EVALUATION]: (state) => state.personalizedEvaluation,

  [GETTER.PENDING]: (state) => state.pending,

  [GETTER.LOADING]: (state) => state.loading,

  [GETTER.SNACKBAR]: (state) => {
    return state.snackbar
  },

  [GETTER.CONTACTS]: (state) => state.evaluation && state.evaluation.contacts ? state.evaluation.contacts : [],

  [GETTER.GET_MACROTREND]: (state) =>
    (macrotrendId) =>
      state.evaluation.macrotrends.find(elem => elem.id === macrotrendId),

  [GETTER.IS_MACROTREND_COMPLETE]: (state) =>
    (macrotrendId) => {
      const mt = state.evaluation.macrotrends.find(elem => elem.id === macrotrendId)
      return mt && mt.microtrends
    },

  [GETTER.CRITERIAS]: (state) => state.evaluation && state.evaluation.criterias ? state.evaluation.criterias : [],

  [GETTER.PROGRESS]: (state) => {
    if (state.evaluation.macrotrends === null || state.evaluation.macrotrends.length === 0) {
      return 0
    }

    const done = state
      .evaluation
      .macrotrends
      .map(elem => elem.evaluations === null ? 0 : 1)
      .reduce((a, b) => a + b)

    return done / state.evaluation.macrotrends.length
  },

  [GETTER.PROGRESS_FORMATTED]: (state, getters) => {
    const formatter = new Intl.NumberFormat(getters[GETTER.LOCALE], {
      style: 'percent',
      // minimumFractionDigits: 1,
      maximumFractionDigits: 1
    })
    return formatter.format(getters[GETTER.PROGRESS])
  },

  [GETTER.MICROTREND]: (state) => state.microtrend,

  [GETTER.COMPLETED]: (state) => {
    const done = state
      .evaluation
      .macrotrends
      .map(elem => elem.evaluations === null ? 0 : 1)
      .reduce((a, b) => a + b)
    const total = state.evaluation.macrotrends.length
    return done === total && total > 0
  },

  [GETTER.GET_NEXT_MACROTREND]: (state) => (last) => {
    const idx = state.evaluation.macrotrends.findIndex(elem => elem.id === last)
    if (idx === -1) {
      return null
    }

    return state.evaluation.macrotrends[idx + 1] || null
  },

  [GETTER.ERROR]: (state) => state.error
}
