import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import { LOCALE } from '../store/getter-types'
import { DE } from '../model/locale'

Vue.use(VueI18n)

export default new VueI18n({
  locale: store.getters[LOCALE],
  fallbackLocale: DE
})
