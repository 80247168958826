import * as MUTATION from './mutation-types'
import Vue from 'vue'
import { init, initSnackbar } from './state'

export default {
  [MUTATION.ADD_PARTICIPANT_ID]: (state, payload) => {
    Vue.set(state.ids, payload.publicEvaluationId, payload.participantId)
  },

  [MUTATION.REMOVE_PARTICIPANT_ID]: (state, payload) => {
    Vue.delete(state.ids, payload)
  },

  [MUTATION.RESET]: (state) => {
    state = init()
  },

  [MUTATION.RESET_SNACKBAR]: (state, payload) => {
    state.snackbar = initSnackbar()
  },

  [MUTATION.SHOW_SNACKBAR]: (state, payload) => {
    state.snackbar = {
      ...payload
    }
  },

  [MUTATION.SET_PENDING]: (state, payload) => {
    state.pending = payload
  },

  [MUTATION.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },

  [MUTATION.SET_LOCALE]: (state, payload) => {
    state.locale = payload
  },

  [MUTATION.SET_PUBLIC_EVALUATION]: (state, payload) => {
    Vue.set(state, 'evaluation', payload)
  },

  [MUTATION.UPDATE_EVALUATION]: (state, payload) => {
    const idx = state.evaluation.macrotrends.findIndex(elem => elem.id === payload.macrotrendId)
    Vue.set(state.evaluation.macrotrends[idx], 'evaluations', payload.values)
  },

  [MUTATION.EXTEND_MACROTREND]: (state, payload) => {
    const idx = state.evaluation.macrotrends.findIndex(elem => elem.id === payload.macrotrendId)
    Vue.set(state.evaluation.macrotrends[idx], 'microtrends', payload.microtrends)
  },

  [MUTATION.SET_MICROTREND]: (state, payload) => {
    Vue.set(state, 'microtrend', payload)
  },

  [MUTATION.SET_ERROR]: (state, payload) => {
    Vue.set(state, 'error', payload)
  },

  [MUTATION.RESET_ERROR]: (state) => {
    Vue.set(state, 'error', null)
  },

  [MUTATION.SET_PERSONALIZED_EVALUATION]: (state, payload) => {
    Vue.set(state, 'personalizedEvaluation', payload)
  }
}
