import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../plugins/i18n'
import store from '../store'

import DefaultLayout from '../layout/default'
import EmptyLayout from '../layout/empty'

import { DE, EN } from '@/model/locale'
import { ERROR_404 } from '@/model/errors'

import { SET_LOCALE, SHOW_SNACKBAR } from '@/store/mutation-types'
import { LOCALE, LOADED } from '@/store/getter-types'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: DefaultLayout,
    children: [
      {
        path: 'error',
        redirect: to => ({
          params: {
            code: ERROR_404,
            locale: to.locale || DE
          }
        })
      },
      {
        path: 'error/:code',
        name: 'error',
        props: (route) => ({
          code: route.params.code || ERROR_404
        }),
        component: () => import(/* webpackChunkName: "error" */ '../views/error.vue')
      },
      {
        path: ':publicEvaluationId',
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
            props: true
          },
          {
            path: 'list',
            name: 'overview',
            component: () => import(/* webpackChunkName: "overview" */ '../views/overview.vue'),
            props: true
          },
          {
            path: 'personalized/:personalizedParticipantId',
            name: 'personalized',
            component: () => import(/* webpackChunkName: "personalized" */ '../views/home.vue'),
            props: true
          },
          {
            path: 'evaluate/:megatrendId/:macrotrendId',
            name: 'evaluate',
            component: () => import(/* webpackChunkName: "evaluate" */ '../views/evaluate.vue'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: to => ({
      name: 'error',
      params: {
        locale: DE,
        code: ERROR_404
      }
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.locale) {
    i18n.locale = to.params.locale
    store.commit(SET_LOCALE, to.params.locale)
  }
  next()
})

const msg = {
  [DE]: 'Starte die Applikation neu.',
  [EN]: 'Restart application.'
}

router.beforeEach((to, from, next) => {
  const exclude = ['home', 'personalized', 'error']
  if (!exclude.includes(to.name) && !store.getters[LOADED]) {
    store.commit(SHOW_SNACKBAR, { color: 'error', message: msg[store.getters[LOCALE]] })
    next({
      name: 'home',
      params: to.params
    })
  } else {
    next()
  }
})

export default router
