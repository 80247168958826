import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import fetch from 'node-fetch'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import store from '@/store'
import { LOCALE } from '@/store/getter-types'
import { runtimeConfig } from '@/util/runtime-config'

const httpLink = createHttpLink({
  uri: runtimeConfig('VUE_APP_BASE_URI') + '/graphql/anon',
  fetch: fetch
})

const addHeadersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-accept-language': store.getters[LOCALE]
    }
  }
})

export const apolloClient = new ApolloClient({
  link: addHeadersLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production'
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler ({
    graphQLErrors,
    networkError
  }) {
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.log('GQL Error', { g: graphQLErrors })
      graphQLErrors.map(({
        message,
        locations,
        path
      }) =>
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`)
    }
  }
})

Vue.use(VueApollo)
export default apolloProvider
