export const ADD_PARTICIPANT_ID = 'ADD_PARTICIPANT_ID'
export const REMOVE_PARTICIPANT_ID = 'REMOVE_PARTICIPANT_ID'
export const SET_PUBLIC_EVALUATION = 'SET_PUBLIC_EVALUATION'
export const SET_LOCALE = 'SET_LOCALE'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const RESET_SNACKBAR = 'RESET_SNACKBAR'
export const RESET = 'RESET'
export const SET_PENDING = 'SET_PENDING'
export const SET_LOADING = 'SET_LOADING'
export const SET_PERSONALIZED_EVALUATION = 'SET_PERSONALIZED_EVALUATION'
export const UPDATE_EVALUATION = 'UPDATE_EVALUATION'
export const SET_MICROTREND = 'SET_MICROTREND'
export const EXTEND_MACROTREND = 'EXTEND_MACROTREND'
export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
