<template>
  <v-footer
    fixed
  >
    <v-dialog
      v-model="meta"
      max-width="650"
    >
      <component :is="modal" />
    </v-dialog>
    <v-layout class="to-footer">
      <a href="#"
         @click="open($event, 'imprint')"
      >
        {{ $t('imprint') }}
      </a>
      <span>|</span>
      <a href="#"
         @click="open($event, 'data-protection')"
      >
        {{ $t('dataProtection') }}
      </a>
      <v-spacer />
      <a target="_blank"
         href="https://www.trendone.com/">&copy; Trendone GmbH</a>
    </v-layout>
  </v-footer>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LOCALE } from '../../store/getter-types'

import ImprintDE from '../meta/de/imprint'
import DataProtectionDE from '../meta/de/data-protection'
import ImprintEN from '../meta/en/imprint'
import DataProtectionEN from '../meta/en/data-protection'

@Component({
  components: {
    'de-imprint': ImprintDE,
    'de-data-protection': DataProtectionDE,
    'en-imprint': ImprintEN,
    'en-data-protection': DataProtectionEN
  }
})
export default class AppFooter extends Vue {
  @Getter(LOCALE) locale
  meta = false
  modal = null

  open (evt, what) {
    this.modal = this.locale + '-' + what
    this.meta = true
  }

  close () {
    this.meta = false
  }
}
</script>

<i18n>
{
  "de": {
    "imprint": "Impressum",
    "dataProtection": "Datenschutzerklärung"
  },
  "en": {
    "imprint": "Imprint",
    "dataProtection": "Privacy policy"
  }
}
</i18n>
